<template>
	<div>
		<main-header></main-header>
		<menu-intro :menuKr="menuKr" :menuEn="menuEn"></menu-intro>
		<div class="container--center userguide">
			<div class="servicecontent__title">
				<h2>이용안내</h2>
				<span>서비스 이용 절차</span>
			</div>
			<div class="userguide__processbox">
				<div class="userguide__processbox--description">
					<i class="fas fa-phone-volume fa-4x"></i>
					<span>일반회원</span>
				</div>
				<i class="fas fa-angle-right fa-2x"></i>
				<div class="userguide__processbox--description">
					<i class="fas fa-envelope-open-text fa-4x"></i>
					<span>기업회원 전환 신청</span>
				</div>
				<i class="fas fa-angle-right fa-2x"></i>
				<div class="userguide__processbox--description">
					<i class="fas fa-sign-in-alt fa-4x"></i>
					<span>서비스 구매 및 상담</span>
				</div>
				<i class="fas fa-angle-right fa-2x"></i>
				<div class="userguide__processbox--description">
					<i class="fas fa-download fa-4x"></i>
					<span>솔루션 구축 및 이용</span>
				</div>
				<i class="fas fa-angle-right fa-2x"></i>
				<div class="userguide__processbox--description">
					<i class="far fa-check-circle fa-4x"></i>
					<span>완료<br />(서비스 오픈)</span>
				</div>
			</div>
			<div class="userguide__processdetail">
				<div class="userguide__processdetail--text">
					<h3>일반회원</h3>
					<ul>
						<li>
							일반 회원은 기업 회원으로 전환한 후에 서비스를 구매할 수 있습니다.
						</li>
						<li>
							관련 문의 사항이 있을 경우 1:1 문의하기로 문의해주시기 바랍니다.
						</li>
					</ul>
					<div class="userguide__btnbox">
						<button class="btn--md btn--fill-accent">
							<router-link to="/inquiry">1:1 문의</router-link>
						</button>
						<button class="btn--md btn--fill-accent">
							<router-link to="/faq">자주 하는 질문</router-link>
						</button>
					</div>
				</div>

				<div class="userguide__processdetail--text">
					<h3>기업회원 전환 신청</h3>
					<ul>
						<li>
							기업 회원 전환 신청하면 FCTS에서 검토 후에 기업회원으로
							전환됩니다. (영업일 기준 3 ~ 5일 소요)
						</li>
						<li>
							검토 단계에서 추가적으로 서류가 필요할 시에 요청할 수 있습니다.
						</li>
					</ul>
					<ul>
						<li>
							<strong>온라인 접수:</strong> 아래 기업 전환 양식 파일을 다운로드
							후 작성하여 담당자 이메일(khjaay@four-chains.com)로 제출합니다.
						</li>
						<li>
							<strong>오프라인 접수 :</strong> 담당자와 연락 후, 우편(빠른 등기)
							또는 팩스로 제출합니다.
						</li>
					</ul>
					<div class="userguide__btnbox">
						<button class="btn--md btn--fill-accent">
							<router-link to="/userauth">
								기업회원 전환 작성 방법
							</router-link>
						</button>
						<button class="btn--md btn--fill-accent" @click="download('1')">
							기업회원 전환 양식
						</button>
					</div>
				</div>

				<div class="userguide__processdetail--text">
					<h3>서비스 구매 및 상담</h3>
					<ul>
						<li>기업회원 전환 후 즉시 서비스 구매가 가능합니다.</li>
						<li>
							구매 관련 문의 사항이 있으실 경우 1:1 문의하기로 문의 해주시기
							바랍니다.
						</li>
					</ul>
				</div>

				<div class="userguide__processdetail--text">
					<h3>솔루션 구축 및 이용</h3>
					<ul>
						<li>개발자 사이트 이동 후 API 연동 및 이용 방식을 확인합니다.</li>
						<li>
							서비스 이용에 불편이 있으실 경우 1:1 문의하기로 문의 해주시기
							바랍니다.
						</li>
					</ul>
					<div class="userguide__btnbox">
						<button class="btn--md btn--fill-accent">
							<router-link to="/warning">개발자 사이트</router-link>
						</button>
					</div>
				</div>

				<div class="userguide__processdetail--text">
					<h3>5. 완료 (서비스 오픈)</h3>
					<ul>
						<li>
							주기적으로 업데이트 및 개발자 사이트 확인 하여 최신 으로 업데이트
							및 유지 해주시기 바랍니다.
						</li>
					</ul>
				</div>
			</div>
		</div>
		<Footer />
	</div>
</template>

<script>
import downloadService from '../services/downloadService';
import Footer from '../components/Footer.vue';
import MainHeader from '../components/MainHeader.vue';
import MenuIntro from '@/components/common/MenuIntro.vue';
export default {
	components: { MainHeader, Footer, MenuIntro },
	data() {
		return {
			menuKr: '이용안내',
			menuEn: 'User Guide',
		};
	},
	methods: {
		download(param) {
			downloadService
				.download(param)
				.then(res => {
					const url = window.URL.createObjectURL(new Blob([res.data]));
					let fileName = '';
					fileName = res.headers['content-disposition'].split('filename=')[1];
					const link = document.createElement('a');
					link.href = url;
					link.setAttribute('download', fileName); //or any other extension
					document.body.appendChild(link);
					link.click();
				})
				.catch(err => {
					console.log(err);
				})
				.finally(() => {
					// whatever
				});
		},
	},
};
</script>

<style></style>
